import React, { useState, useEffect, useMemo } from "react"
import PropTypes from 'prop-types'
import Filters from '../components/Filters'
import Card from './Card'

const MvpsSection = props => {
  const { items } = props

  // const sortedItems = useMemo(() => {
  //   return [...items].sort( (a,b) => (a.node.fullName > b.node.fullName) ? 1 : -1)
  // }, [])

  const initialCardsToShow = 6
  const cardsPerLoad = 12
  const [cardsToShow, setCardsToShow] = useState(initialCardsToShow)
  const [selectedAward, setSelectedAward] = useState('')
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [filteredItems, setFilteredItems] = useState([...items])
  const [displayLoading, setDisplayLoading] = useState(true)
  const [displayShowMoreBtn, setDisplayShowMoreBtn] = useState(filteredItems.length > initialCardsToShow)
  // SIDE EFFECT 1: If User changes any of the filter values, the number of cards to display should be reset and the filter should be performed
  // If not, just reset the filterItems to use shuffledItems if those has different length
  useEffect(() => {
    if(selectedProduct || selectedAward || selectedRegion || selectedCountry) {
      setCardsToShow(initialCardsToShow)
      filterItems()
    } else if(filteredItems.length !== items.length) {
      setFilteredItems([...items])
    }
  }, [selectedAward, selectedProduct, selectedRegion, selectedCountry])

  // SIDE EFFECT 2: If cardsToShow or filteredItems changed, set displayShowMoreBtn based on the length of filteredItems and the amout of cardsToShow
  useEffect(() => {
    setDisplayLoading(false)
    setDisplayShowMoreBtn(filteredItems.length > cardsToShow)
  }, [cardsToShow, filteredItems])

  // Create filter values based on all the items
  const countries = []
  const products = []
  const regions = []
  const awards = []

  items.map( edge => {
    const { node } = edge

    // Create list of Countries
    if ( typeof node.location === 'string' && node.location.length > 0 ) {
      if(node.location.includes('Korea, Republic of')){
        countries.push('Korea, Republic of')
      }
      else{
        const countrySplit = node.location.split(',')
        const country = countrySplit.length > 1 ? countrySplit[1] : countrySplit[0]
        if (typeof country === 'string' && country.length > 0) {
          if (!countries.includes(country.trim())) {
            countries.push(country.trim())
          }
        }
      }

    }

    // Create list of Products
    if (typeof node.productUsing === 'string' && node.productUsing.length > 0) {
      const productSplit = node.productUsing.split(';')
      productSplit.map( product => {
        product = typeof product === 'string' ? product.trim() : ""; 
        if (product != "" && !products.includes(product)) {
          products.push(product)
        }
      })
    }

    // Create list of Regions
    if (typeof node.region === 'string' && node.region.length > 0 ) {
      if (!regions.includes(node.region)) {
        regions.push(node.region)
      }
    }

    // Create list of Awards
    if (typeof node.role === 'string' && node.role.length > 0) {    
      if (!awards.includes(node.role)) {
        awards.push(node.role)
      }
    }
  })
  
  countries.sort()
  products.sort()
  regions.sort()
  awards.sort()
  //

  // Filter Items when User change a filter value
  const filterItems = () => {
    let awardFilterPass, productFilterPass, regionFilterPass, countryFilterPass

    let filteredItems = items.filter( edge => {
      const { node } = edge

      awardFilterPass = selectedAward === '' || (node.role !== null &&  node.role.toLowerCase() === selectedAward.toLowerCase())
      productFilterPass = selectedProduct === '' || (node.productList !== null && node.productList.includes(selectedProduct))
      countryFilterPass = selectedCountry === '' || (node.country != null && node.country.toLowerCase() === selectedCountry.toLowerCase())
      regionFilterPass = selectedRegion === '' || (node.region.toLowerCase() === selectedRegion.toLowerCase())

      return awardFilterPass && productFilterPass && countryFilterPass && regionFilterPass
    })

    filteredItems.sort( (a,b) => (a.node.fullName > b.node.fullName) ? 1 : -1)

    setFilteredItems(filteredItems)
  }
  //

  // HANDLERS
  const handleAwardOnChange = (value) => {
    setSelectedAward(value)
  }

  const handleProductOnChange = (e) => {
    setSelectedProduct(e.nativeEvent.target.value)
  }

  const handleCountryOnChange = (e) => {
    setSelectedCountry(e.nativeEvent.target.value)
  }

  const handleRegionOnChange = (e) => {
    setSelectedRegion(e.nativeEvent.target.value)
  }

  const handleClearFilters = () => {
    if(selectedAward || selectedProduct || selectedRegion || selectedCountry) {
      setSelectedAward('')
      setSelectedProduct('')
      setSelectedRegion('')
      setSelectedCountry('')
      setCardsToShow(initialCardsToShow)
    }
  }

  const handleViewMore = () => {
    setCardsToShow(cardsToShow + cardsPerLoad)
  }
  

  return (
    <div>
      <Filters 
        products = {products}
        countries = {countries}
        regions = {regions}
        awardOnChangeHandler = {handleAwardOnChange}
        productOnChangeHandler = {handleProductOnChange}
        countryOnChangeHandler = {handleCountryOnChange}
        regionOnChangeHandler = {handleRegionOnChange}
        clearFilterHandler={handleClearFilters}
        selectedProduct={selectedProduct}
        selectedRegion={selectedRegion}
        selectedCountry={selectedCountry}
      />

      {filteredItems.length > 0 && displayLoading && (
        <center className="slds-p-top_xx-large H(550)">
          <tds-spinner size="large"></tds-spinner>
        </center>
      )}

      {!displayLoading && filteredItems.length > 0 && (
        <>
          <tm-trailblazers-a>
            {
              filteredItems.map( (edge, index) => {
                if (index < cardsToShow) {
                  const { node } = edge
                  return (<Card key={`mvp-card-item-${node.vanitySlug}`} data={node} />)
                }
              })
            }
          </tm-trailblazers-a>
        </>
      )}

      {!displayLoading && filteredItems.length > 0 && displayShowMoreBtn && (
        <div id="btnShowMore" className='slds-text-align_center slds-p-top_xx-large'>
          <button className="slds-button slds-button_neutral tds-button_neutral" type="button" onClick={handleViewMore}>Show More</button>
        </div>
      )}
      
    </div>
  )
}

MvpsSection.propTypes = {
  items: PropTypes.array.isRequired
}

export default MvpsSection
