// using CommonJS export because function is used in gatsby-node.js file and runs during node build

module.exports = {

  mvps: (data) => {
    return data.map( edge => {
      let node = edge;
        
      if (edge.node !== undefined) {
        node = edge.node;
      }

      let fullName = ''
      let country = ''
      let photoURL = ''
      let twitterLink = ''
      let twitterText = ''
      let linkedinLink = ''
      let linkedinText = ''
      let profileHref = ''
      let cardRole = ''
      let region = ''
      let role = ''
      
      const productList = [];

      fullName = `${node.firstName} ${node.lastName}`

      // Fix country field
      if (typeof node.location === 'string' && node.location.length > 0) {
        if(node.location.includes('Korea, Republic of')){
          country = 'Korea, Republic of';
        }
        else{
          let countrySplit = node.location.split(',');
          country = countrySplit.length > 1 ? countrySplit[1] : countrySplit[0];
          if (typeof country === 'string' && country.length > 0) {
            country = country.trim();
          }
        }
      }

      // Fix product field
      if (typeof node.productUsing === 'string' && node.productUsing.length > 0) {
        let productSplit = node.productUsing.split(';');
        productSplit.map( product => {
          product = typeof product === 'string' ? product.trim() : "";  

          if (product !== '' && !productList.includes(product)) {
            productList.push(product);
          }
        })
      }

      // Fix region field
      if (typeof node.region === 'string' && node.region.length > 0 ) {
        region = node.region.trim();
      }

      // Fix award field
      if (typeof node.role === 'string' && node.role.length > 0) {    
        role = node.role.trim();         
      }

      const nodeLinkedin = module.exports.prepareMediaLink(node.linkedin)
      const nodeTwitter = module.exports.prepareMediaLink(node.twitter)

      photoURL = (node.photoURL == null) || (node.photoURL == '') ? 'https://developer.salesforce.com/resources2/mvp/astro.svg' : node.photoURL;   
      
      twitterLink = nodeTwitter != null && nodeTwitter != '' ? `https://twitter.com/${nodeTwitter}` : '';
      twitterText = nodeTwitter != null && nodeTwitter != '' ? `twitter.com/${nodeTwitter}` : '';
      linkedinLink = nodeLinkedin != null && nodeLinkedin != '' ? `https://linkedin.com/in/${nodeLinkedin}` : '';
      linkedinText = nodeLinkedin != null && nodeLinkedin != '' ? `linkedin.com/in/${decodeURI(nodeLinkedin)}` : '';

      console.log(twitterLink, twitterText)

      profileHref = node.vanitySlug != null && node.vanitySlug !='' ? `https://www.salesforce.com/trailblazer/${node.vanitySlug}` : '';
  
      cardRole = (node.company != null && node.company != '') && (node.jobTitle != null && node.jobTitle != '') 
                      ? `${node.jobTitle}, ${node.company}`
                      : (node.company == null || node.company == '') && (node.jobTitle != null && node.jobTitle != '') 
                        ? `${node.jobTitle}`
                        : (node.company != null && node.company != '') && (node.jobTitle == null || node.jobTitle == '') 
                          ? `${node.company}` 
                          : '';
      
      return {
        node: {
          ...node, 
          country,
          region,
          photoURL,
          twitterLink,
          twitterText,
          linkedinLink,
          linkedinText,
          profileHref,
          cardRole,
          productList,
          role,
          fullName
        }
      }
    })
  },

  shuffleItems: (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;     
    }

    return array;
  },

  prepareMediaLink: (value) => {
    let newVal = value

    if(newVal && newVal.length > 0) {
      newVal = newVal === 'n/a' ? '' : newVal;
      newVal = newVal === 'N/A' ? '' : newVal;

      // Remove trailing slash
      newVal = newVal.replace(/\/$/, '')
      newVal = newVal.replace('https://linkedin.com/in/', '')
      newVal = newVal.replace('https://twitter.com/', '')

      newVal = newVal.toLowerCase() === 'none' ? '' : newVal;
      newVal = newVal === '-' ? '' : newVal;
    }
    

    return newVal
  }
}